<template>
	<div class="header-dropdown">
		<CDropdown in-nav class="c-header-nav-items" placement="bottom-end" add-menu-classes="pt-0">
			<template #toggler>
				<CHeaderNavLink>
					<div class="c-avatar">
						<img v-lazy="avatar" class="c-avatar-img" />
					</div>
				</CHeaderNavLink>
			</template>

			<CDropdownHeader tag="div" class="text-left" color="light">
				<strong>{{ $t("Header.Setting") }}</strong>
			</CDropdownHeader>
			<CDropdownItem @click="checkShowChangePwd(true)">
				<CIcon name="cil-settings" /> {{ $t("Header.ChangePwd") }}
			</CDropdownItem>
			<CDropdownDivider />
			<CDropdownItem @click="checkShowLogout(true)">
				<CIcon name="cil-lock-locked" /> {{ $t("Header.Logout") }}
			</CDropdownItem>
		</CDropdown>
		<Logout v-if="isShowLogout" @hide="checkShowLogout" />
		<ChangePassword
			v-if="isShowChangePwd"
			@hide="checkShowChangePwd"
			@change-success="changePwdSuccess"
		/>
		<CustomModal
			v-if="isChangePasswordSuccess"
			:is-show-close="false"
			:is-show-cancel="false"
			:submit-text="$t('Button.OK')"
			@submit-modal="gotoLogin"
		>
			<template #content>
				{{ $t("ChangePwd.Success") }}
			</template>
		</CustomModal>
	</div>
</template>

<script>
import avatar from "@/assets/img/avatar_default.jpg"
import {
	CHANGE_STATUS_LOGOUT_MODAL,
	CHANGE_STATUS_CHANGE_PASSWORD_MODAL,
} from "@/modules/authentication/store/mutation-types"
import { createNamespacedHelpers } from "vuex"
const { mapMutations, mapState } = createNamespacedHelpers("authentication")

export default {
	name: "TheHeaderDropdownAccnt",
	components: {
		Logout: () => import("@/modules/authentication/components/Logout.vue"),
		ChangePassword: () => import("@/modules/authentication/components/ChangePassword.vue"),
	},
	data() {
		return {
			avatar,
			isChangePasswordSuccess: false,
		}
	},
	computed: {
		...mapState({
			isShowLogout: "showLogoutModal",
			isShowChangePwd: "showChangePwd",
		}),
	},

	methods: {
		...mapMutations({
			CHANGE_STATUS_LOGOUT_MODAL,
			CHANGE_STATUS_CHANGE_PASSWORD_MODAL,
		}),
		checkShowLogout(status = false) {
			this.CHANGE_STATUS_LOGOUT_MODAL(status)
			this.$store.commit("set", ["modalShow", status])
		},
		checkShowChangePwd(status = false) {
			this.CHANGE_STATUS_CHANGE_PASSWORD_MODAL(status)
			this.$store.commit("set", ["modalShow", status])
		},
		goToProfile() {
			this.$router.push("/profile")
		},
		changePwdSuccess() {
			this.isChangePasswordSuccess = true
			this.$store.commit("set", ["modalShow", true])
		},
		gotoLogin() {
			this.isChangePasswordSuccess = false
			this.$store.commit("set", ["modalShow", false])
			this.$router.push("/login")
		},
	},
}
</script>

<style lang="scss">
@import "@/assets/scss/_variables";

.header-dropdown {
	.c-icon {
		margin-right: 0.3rem;
	}
	.dropdown-item.active,
	.dropdown-item:active {
		background-color: unset;
		color: $black;
	}

	.dropdown-item:hover,
	.dropdown-item:focus {
		background-color: $sidebar-hover-color;
	}

	.dropdown-item {
		margin: 0.5em 0;
	}

	.dropdown-menu.show {
		padding-bottom: 0 !important;
	}
}
</style>
